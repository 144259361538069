import React from 'react'
import Link from 'gatsby-link'
import axios from 'axios'
import './style.css'


class WordForm extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        words: "",
      }
      this.handleWord = this.handleWord.bind(this)
      this.submitWords = this.submitWords.bind(this)
  }

  
  postToAirTable(word){
    axios.post("https://api.airtable.com/v0/app1POYiufgjnmVCa/words?maxRecords=10&view=Grid%20view", {
    "fields": {
        "words": word
      }
    }, {
    headers: {"Authorization": `Bearer ${process.env.GATSBY_AT_KEY}`},
    }).then((response) => {
      console.log('RESPONSE', response)
      const words = response.data.fields.words
      this.setState({
        words,
        sketch: true
      }, () => {
          console.log('AFTER POST', this.state.words);
          this.props.hideSplash()
      })
    
    })
        .then((responseData) => {
            // const words = responseData.records
            // this.setState({words}, () => {
            //     console.log('AFTER POST', this.state.words);
            // })
      })
  }

  handleWord(event) {
    this.setState({
      words: event.target.value
    })
  }

 submitWords(){
    let word = this.state.words
    this.postToAirTable(word)
    
 }

  render() {
      return (
        <div className="word-form-splash">
          <div className="options-back"></div>
          <div className="word-form-form">
            <h4>Please enter the first word that comes to your mind when think about death and then click the button below to enter.</h4>
            <input value={this.state.words} name="word1" onChange={this.handleWord} />
            <div className="word-submit" onClick={this.submitWords}>Enter the Future of Death</div>
            <Link to="/table-of-contents"><p>I don't have the words today.</p></Link>
          </div>
        </div>
      )
  }
}

export default WordForm
