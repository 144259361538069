import Courier from './Courier.otf'
export default function sketch2 (p)  {
  let deathWords; 
  let xpos = []; 
  let ypos = [];
  let xSpeed = [];
  let ySpeed = [];
  let r = [];
  let g = [];
  let b = [];
  let size = [];
  let ballCount = 100;
  let loading = true;
  let t = 0;
  let COLORS = ["#ed6191","#f6d9d9", "#f6d9d9","#3c8d8c"  ];
  let myFont, colorIndex, myColor;
  let thisColor = [];
  let ballOrText = []

  p.preload = function() {
    myFont = p.loadFont(Courier);
    fetch("https://api.airtable.com/v0/app1POYiufgjnmVCa/words?maxRecords=1000&view=Grid%20view", {
      headers: {"Authorization": `Bearer ${process.env.GATSBY_AT_KEY}`}
    }).then((response) => response.json())
        .then((responseData) => {
           deathWords = responseData.records
           console.log('death', deathWords)
      })
  }


  p.setup = function() {
    p.createCanvas(p.windowWidth, p.windowHeight);
 

    
    p.noStroke();

  };

  

  p.draw = function() {
      // make a x and y grid of ellipses
  for (let x = 0; x <= p.width; x = x + 10) {
    for (let y = 0; y <= p.height; y = y + 30) {
      // starting point of each circle depends on mouse position
      let xAngle = p.map(p.mouseX + 5, 0, p.width, -2 * p.PI, 4 * p.PI, true);
      let yAngle = p.map(p.mouseY, 0, p.height, -4 * p.PI, 4 * p.PI, true);
      // and also varies based on the particle's locppation
      let angle = xAngle * (x / p.width) + yAngle * (y / p.height);

      // each particle moves in a circle
      let myX = x + 20 * p.cos(p.random(3) * p.PI * t + angle);
      let myY = y + 20 * p.sin(p.random(2) * p.PI * t + angle);

      p.ellipse(myX, myY, p.random(3)); // draw particle
    }

  }
  t = t + 0.01; // update time
  if (p.kill === true) {
      console.log('holy fuck')
      p.remove()
  }




  
    p.background('rgba(0,0,0, 0.25)')
    if (!deathWords && loading == true) {
      return
    } else if (deathWords && loading == true) {
      customSetup()
    } 
    
    else {
      for(let i=0; i < deathWords.length; i++) {
        // xpos[i] = xpos[i] + xSpeed[i];
        xpos[i] = xpos[i] + p.random(-2, 2)
        ypos[i] = ypos[i] + ySpeed[i];
  
        if(xpos[i] < p.width/6 || xpos[i] > p.width- p.width/6) {
          xSpeed[i] *= -1;
        }
        if(ypos[i] < 0 || ypos[i] > p.height) {
          ySpeed[i] *= -1;
          if (p.random(0,2) > 1)
          ballOrText[i]= 1
        }
  
  
        // p.fill(r[i], g[i], b[i]);
      
        p.fill(thisColor[i])
        p.textFont(myFont);
        p.textSize(size[i])
        if (ballOrText[i] == 1) {
          p.text(deathWords[i].fields.words, xpos[i], ypos[i])
        } else {
          p.ellipse(xpos[i], ypos[i], size[i], size[i])
        } 
      }
    }
   
  };
  
  const customSetup = function() {
    for (let i=0; i < deathWords.length; i++) {
      xpos[i] = p.width/2;
      ypos[i] = p.height/2
      xSpeed[i] = p.random(-2, 2)
      ySpeed[i] = p.random(-3, 3)
      r[i] = p.random(0,255);
      g[i] = p.random(0,255);
      b[i] = p.random(0,255);
      ballOrText[i]= 0
      colorIndex = Math.floor(p.random(0, COLORS.length))
      console.log('COLOR INDEX',)
      myColor = COLORS[colorIndex];
      console.log('my color', myColor)
      thisColor[i] = myColor;
      size[i] = p.random(10, 20)
      loading=false;
    }
  }


  p.windowResized = () => {
    // numbers = [true];
    // count = 1;
    // sequence = [];
    // index = 0;

    p.resizeCanvas(p.windowWidth, p.windowHeight);

  
  };
}
